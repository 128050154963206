<script lang="ts">
import {defineComponent} from 'vue'
import {mapActions, mapStores} from "pinia";
import {UsersUrl, useUsersUrlsStore} from "@/stores/UsersUrlsStore";
import {useTasksStore} from "@/stores/TasksStore";
// import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
// import { faTrash } from '@fortawesome/free-solid-svg-icons'
export default defineComponent({
  name: "UsersUrlsView",
  data() {
    return {
      url: '',
      filtre: ''
    }
  },
  emits:["urlClicked"],
  beforeMount() {
    this.UsersUrlsStore.fetchData()
    this.TasksStore.fetchData()
  },
  methods: {
    ...mapActions(useUsersUrlsStore,['addUrl','removeUrl']),
    urlByHostname(hostname : string) {
      return this.UsersUrlsStore.data.filter((url)=>url.url.includes(hostname))
    },
    countUrlByHostname(hostname : string) {
      return this.UsersUrlsStore.data.filter((url)=>url.url.includes(hostname)).length
    },
    pathname(url: string) {
      return new URL(url).pathname
    }
  },
  computed: {
    ...mapStores(useUsersUrlsStore),
    ...mapStores(useTasksStore),
    urlsFiltered() : UsersUrl[] {
      if (this.filtre != '') {
        return this.UsersUrlsStore.data.filter((url)=>url.url.includes(this.filtre))
      }
      return this.UsersUrlsStore.data
    },
    hostnames() : string[] {
      const hostnames : string[] = []
      this.urlsFiltered.forEach(url => {
        try {
          const source = new URL(url.url).hostname;
          if (!hostnames.includes(source)) {
            hostnames.push(source)
          }
        } catch (e) {
          hostnames.push(url.url)
          console.log(e)
        }
      })
      return hostnames
    }
  }
})
</script>

<template>
  <v-sheet rounded min-height="80vh" >
    <v-container>
      <v-row align="center" no-gutters class="mb-3">
        <v-col cols="8">
          <v-text-field hide-details v-model="url" variant="underlined" label="Ajouter un url"></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-btn @click="this.addUrl(url)">Ajouter</v-btn>
        </v-col>
      </v-row>

      <v-row align="center" no-gutters class="mb-3">
        <v-col cols="12">
          <v-text-field hide-details v-model="filtre" variant="underlined" label="Filtrer"></v-text-field>
        </v-col>
      </v-row>

      <v-row
             no-gutters
             align="center">
        <v-col cols="">
          <v-expansion-panels variant="accordion">
            <v-expansion-panel v-for="(hostname,index) in this.hostnames" :key="index">
              <v-expansion-panel-title @click="$emit('urlClicked',hostname)">
                {{ hostname }} ({{countUrlByHostname(hostname)}})
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <v-list-item
                    v-for="url in urlByHostname(hostname)"
                    :key="url.url"
                    @click="$emit('urlClicked',url.url)"
                    align="start" >
                  <v-row align="center">
                    <v-col style="text-wrap: nowrap;text-overflow: ellipsis;overflow: hidden;">
                      {{ pathname(url.url) }}
                    </v-col>
                    <v-col cols="auto">
                      <v-btn
                        icon="mdi-check"
                        size="small"
                        density="compact"
                        :color="this.TasksStore.getTaskById(url.id) ? 'green' : 'grey'"
                        :flat="true"
                        @click="this.TasksStore.getTaskById(url.id) ? this.TasksStore.deleteTask(this.TasksStore.getTaskById(url.id).id) : this.TasksStore.newTask(url.id)">                        
                      </v-btn>
                    </v-col>
                    <v-col cols="auto">
                      <v-btn icon="mdi-delete" size="small" density="compact" :flat="true" @click="this.removeUrl(url.id)"></v-btn>
                    </v-col>
                  </v-row>
                </v-list-item>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<style scoped lang="scss">


</style>