import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UsersUrlsView = _resolveComponent("UsersUrlsView")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_UsersUrlView = _resolveComponent("UsersUrlView")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, {
    "fill-height": "",
    fluid: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "4",
            lg: "3",
            xl: "2"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_UsersUrlsView, { onUrlClicked: _ctx.onUrlClicked }, null, 8, ["onUrlClicked"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, {
            cols: "8",
            lg: "9",
            xl: "10"
          }, {
            default: _withCtx(() => [
              (_ctx.url)
                ? (_openBlock(), _createBlock(_component_UsersUrlView, {
                    key: 0,
                    url: _ctx.url
                  }, null, 8, ["url"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}