import { defineStore } from "pinia";
import httpService from "@/services/HttpService";
import config from "../config.json"
import keycloakService from "@/services/KeycloakService";
import {CheckyUrl} from "@/stores/CheckyUrlsStore";

export interface CheckyUrlCron {
    id: string;
    cron: string;
    url: CheckyUrl;
    created_at: Date;
    updated_at: Date;
}

export const useCheckyUrlsCronsStore = defineStore("CheckyUrlsCronStore",{
    state : () => {
        return {
            data: [] as CheckyUrlCron[]
        }
    },
    actions : {
        fetchData() {
            httpService.getAxiosClient()
                .get(`${config.checky_url}/url_crons`)
                .then(response=> {
                    this.data = response.data
                    this.data.forEach(checkyUrlCheck => {
                        checkyUrlCheck.created_at = new Date(checkyUrlCheck.created_at)
                        checkyUrlCheck.updated_at = new Date(checkyUrlCheck.updated_at)
                    })
                    this.data.sort((a, b) => b.created_at.getTime() - a.created_at.getTime());
                })
        },
        addCron(url: CheckyUrl) {
            console.log({
                    user_id: keycloakService.GetUserId(),
                    url: url
            })
            httpService.getAxiosClient()
                .post(`${config.checky_url}/url_crons`,{
                    // user_id: keycloakService.GetUserId(),
                    url: url
                })
                .then(response=> {
                    this.fetchData()
                })
        },
        deleteCron(id: string) {
            httpService.getAxiosClient()
                .delete(`${config.checky_url}/url_crons/${id}`)
                .then(response=> {
                    this.fetchData()
                })
        },
    },
    getters : {
        getCheckCronByUrlId: (state) => {
            return (url_id : string) => state.data.find(checkyUrlCron => checkyUrlCron.url.id === url_id) as CheckyUrlCron
        },
    }
})