import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-15e908e4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "login" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", null, "Login " + _toDisplayString(_ctx.Login()), 1),
    _createElementVNode("h2", null, "Roles " + _toDisplayString(_ctx.UserRoles()?.join(" ")), 1),
    _createElementVNode("h2", null, "Access Token " + _toDisplayString(_ctx.AccessToken()), 1),
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.LogOut && _ctx.LogOut(...args)))
    }, "Log Out")
  ]))
}