import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ScreenView from "@/views/ScreenView.vue";
import VcfView from "@/views/VcfView.vue";
import CheckyView from "@/views/CheckyView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/vcf',
    name: 'vcf',
    component: VcfView
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // },
  {
    path: '/screens',
    name: 'screens',
    component: ScreenView
  },
  {
    path: '/checky',
    name: 'checky',
    component: CheckyView
  }
]

const router = createRouter({
 // history: createWebHistory(process.env.BASE_URL),
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
