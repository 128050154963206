<script lang="ts">
import {defineComponent} from 'vue';
import {mapActions, mapState} from "pinia";
import {Screen, useScreensStore} from "@/stores/ScreensStore";
import config from "../config.json";


export default defineComponent({
  name: "UsersUrlView",
  data() {
    return {
      config,
      loading: false,
      images: [] as string[]
    }
  },
  beforeMount() {
    useScreensStore().fetchData()
  },
  props : {
    url : String,
  },

  methods: {
    ...mapActions(useScreensStore,['deleteScreen']),
    newScreen(url: string) {
      this.loading = true
      useScreensStore().newScreen(url).then(()=>{
        this.loading = false
      })
    },
    show(index:number) {
      this.images = []
      this.screens.forEach(screen=>{
        this.images.push(config.api_url+screen.img_url)
      })
      this.$viewerApi({
        images: this.images,
        options: {
          initialViewIndex: index,
        }
      })
    },
    showDate(date: string | Date) {
      if (typeof date === "string") {
          date = new Date(date)
      }
      const jour = date.getDate();
      let jourString = jour.toString();

      const mois = date.getMonth() + 1;
      let moisString = mois.toString()

      const annee = date.getFullYear();
      let anneeString = annee.toString();

      const heures = date.getHours();
      let heuresString = heures.toString();

      const minutes = date.getMinutes();
      let minutesString = minutes.toString();

      const secondes = date.getSeconds();
      let secondesString = secondes.toString();

      if (jour < 10) {
        jourString = '0' + jourString;
      }
      if (mois < 10) {
        moisString = '0' + moisString;
      }
      if (heures < 10) {
        heuresString = '0' + heuresString;
      }
      if (minutes < 10) {
        minutesString = '0' + minutesString;
      }
      if (secondes < 10) {
        secondesString = '0' + secondesString;
      }
      const dateFormatee = jourString + '/' + moisString + '/' + anneeString + ' ' + heuresString + ':' + minutesString + ':' + secondesString;

      return dateFormatee
    }
  },
  computed: {
    ...mapState(useScreensStore,['getScreensByUrl','getScreensByHostname']),
    screens() : Screen[] {
      if (this.url?.startsWith('http')) {
        return this.getScreensByUrl(this.url!)
      }
      return this.getScreensByHostname(this.url!)
    }
  }
})
</script>

<template>
  <v-sheet rounded>
    <v-container>
      <v-row>
        <v-col style="text-align: start">
          Url choisi : {{ url }}
          <v-btn v-if="url" @click="this.newScreen(url)" :disabled="loading">Cheeese !</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" md="4" xl="3" v-for="(screen,index) in screens" :key="screen.id">
          <v-sheet elevation="20" class="pa-5 position-relative">
            <v-img @click="show(index)" :src="config.api_url + screen.thumbnail_url"></v-img>
            <br>
            {{ showDate(screen.created_at) }}
            <br>
            <v-btn
                class="position-absolute"
                style="top: 8px; right: 8px"
                color="red"
                size="small"
                @click="this.deleteScreen(screen.id)"
                icon="mdi-delete">
            </v-btn>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<style scoped lang="scss">

</style>