import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_file_input = _resolveComponent("v-file-input")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_sheet = _resolveComponent("v-sheet")!

  return (_openBlock(), _createBlock(_component_v_sheet, {
    class: "mx-auto",
    "max-width": "800"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_form, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_container, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "6"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        "hide-details": "",
                        modelValue: _ctx.lastName,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.lastName) = $event)),
                        variant: "solo",
                        label: "Nom"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "6"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        "hide-details": "",
                        modelValue: _ctx.firstName,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.firstName) = $event)),
                        variant: "solo",
                        label: "Prénom"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "6"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        "hide-details": "",
                        modelValue: _ctx.company,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.company) = $event)),
                        variant: "solo",
                        label: "Entreprise"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "6"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        "hide-details": "",
                        modelValue: _ctx.jobTitle,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.jobTitle) = $event)),
                        variant: "solo",
                        label: "Métier"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "6"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        "hide-details": "",
                        modelValue: _ctx.email,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.email) = $event)),
                        variant: "solo",
                        label: "Email"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "6"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_file_input, {
                        "hide-details": "",
                        onChange: _ctx.handleFileUpload,
                        accept: "image/png, image/jpeg",
                        label: "File input",
                        variant: "solo"
                      }, null, 8, ["onChange"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_divider, { class: "huge_divider" }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "12"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, { cols: "auto" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_btn, {
                                onClick: _ctx.addUrl,
                                "prepend-icon": "mdi-plus"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode("Ajouter un lien")
                                ]),
                                _: 1
                              }, 8, ["onClick"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.urls, (url, index) => {
                        return (_openBlock(), _createBlock(_component_v_row, {
                          key: index,
                          align: "center"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, { cols: "auto" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_btn, {
                                  icon: "mdi-delete",
                                  color: "red",
                                  density: "comfortable",
                                  onClick: ($event: any) => (_ctx.removeUrl(index))
                                }, null, 8, ["onClick"])
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_v_col, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, {
                                  "hide-details": "",
                                  modelValue: url.url,
                                  "onUpdate:modelValue": ($event: any) => ((url.url) = $event),
                                  variant: "solo",
                                  label: "Lien"
                                }, null, 8, ["modelValue", "onUpdate:modelValue"])
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_v_col, { cols: "4" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_select, {
                                  "hide-details": "",
                                  label: "Type",
                                  modelValue: url.type,
                                  "onUpdate:modelValue": ($event: any) => ((url.type) = $event),
                                  items: ['WORK', 'HOME']
                                }, null, 8, ["modelValue", "onUpdate:modelValue"])
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_divider, { class: "huge_divider" }),
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, { cols: "auto" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_btn, {
                                onClick: _ctx.addTel,
                                "prepend-icon": "mdi-plus"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode("Ajouter un numéro de téléphone")
                                ]),
                                _: 1
                              }, 8, ["onClick"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tels, (tel, index) => {
                        return (_openBlock(), _createBlock(_component_v_row, {
                          key: index,
                          align: "center"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, { cols: "auto" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_btn, {
                                  icon: "mdi-delete",
                                  color: "red",
                                  density: "comfortable",
                                  onClick: ($event: any) => (_ctx.removeTel(index))
                                }, null, 8, ["onClick"])
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_v_col, { cols: "5" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, {
                                  "hide-details": "",
                                  modelValue: tel.number,
                                  "onUpdate:modelValue": ($event: any) => ((tel.number) = $event),
                                  variant: "solo",
                                  label: "Téléphone"
                                }, null, 8, ["modelValue", "onUpdate:modelValue"])
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_v_col, { cols: "4" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_select, {
                                  "hide-details": "",
                                  label: "Type",
                                  modelValue: tel.type,
                                  "onUpdate:modelValue": ($event: any) => ((tel.type) = $event),
                                  items: ['WORK', 'HOME']
                                }, null, 8, ["modelValue", "onUpdate:modelValue"])
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_v_col, { cols: "2" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_checkbox, {
                                  "hide-details": "",
                                  modelValue: tel.isPref,
                                  "onUpdate:modelValue": ($event: any) => ((tel.isPref) = $event),
                                  onClick: ($event: any) => (_ctx.setPrefTel(index)),
                                  label: "Pref"
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "onClick"])
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_divider, { class: "huge_divider" }),
                  _createVNode(_component_v_col, { cols: "auto" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_btn, {
                        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.addAddresse())),
                        "prepend-icon": "mdi-plus"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Ajouter une adresse")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.adresses, (adresse, index) => {
                    return (_openBlock(), _createBlock(_component_v_col, {
                      cols: "12",
                      key: index
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, { cols: "auto" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_btn, {
                                  icon: "mdi-delete",
                                  color: "red",
                                  density: "comfortable",
                                  onClick: ($event: any) => (_ctx.removeAdresse(index))
                                }, null, 8, ["onClick"])
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_v_col, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_row, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_col, {
                                      cols: "12",
                                      md: "6"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_text_field, {
                                          "hide-details": "",
                                          variant: "solo",
                                          modelValue: adresse.nom,
                                          "onUpdate:modelValue": ($event: any) => ((adresse.nom) = $event),
                                          label: "Nom de l'adresse (domicile, travail, ...)"
                                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_v_col, {
                                      cols: "12",
                                      md: "6"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_text_field, {
                                          "hide-details": "",
                                          variant: "solo",
                                          modelValue: adresse.rue,
                                          "onUpdate:modelValue": ($event: any) => ((adresse.rue) = $event),
                                          label: "Rue"
                                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_v_col, {
                                      cols: "12",
                                      md: "6"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_text_field, {
                                          "hide-details": "",
                                          variant: "solo",
                                          modelValue: adresse.ville,
                                          "onUpdate:modelValue": ($event: any) => ((adresse.ville) = $event),
                                          label: "Ville"
                                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_v_col, {
                                      cols: "12",
                                      md: "6"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_text_field, {
                                          "hide-details": "",
                                          variant: "solo",
                                          modelValue: adresse.cp,
                                          "onUpdate:modelValue": ($event: any) => ((adresse.cp) = $event),
                                          label: "Code postal"
                                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_v_col, {
                                      cols: "12",
                                      md: "6"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_text_field, {
                                          "hide-details": "",
                                          variant: "solo",
                                          modelValue: adresse.pays,
                                          "onUpdate:modelValue": ($event: any) => ((adresse.pays) = $event),
                                          label: "Pays"
                                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128)),
                  _createVNode(_component_v_divider, { class: "huge_divider" }),
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, { cols: "auto" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_btn, {
                                onClick: _ctx.addReseau,
                                "prepend-icon": "mdi-plus"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode("Ajouter un réseau")
                                ]),
                                _: 1
                              }, 8, ["onClick"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.reseaux, (reseau, index) => {
                        return (_openBlock(), _createBlock(_component_v_row, {
                          key: index,
                          align: "center"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, { cols: "auto" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_btn, {
                                  icon: "mdi-delete",
                                  color: "red",
                                  density: "comfortable",
                                  onClick: ($event: any) => (_ctx.removeReseau(index))
                                }, null, 8, ["onClick"])
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_v_col, { cols: "5" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, {
                                  "hide-details": "",
                                  modelValue: reseau.url,
                                  "onUpdate:modelValue": ($event: any) => ((reseau.url) = $event),
                                  variant: "solo",
                                  label: "Lien"
                                }, null, 8, ["modelValue", "onUpdate:modelValue"])
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_v_col, { cols: "3" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_select, {
                                  "hide-details": "",
                                  label: "Type",
                                  modelValue: reseau.type,
                                  "onUpdate:modelValue": ($event: any) => ((reseau.type) = $event),
                                  items: ["Facebook" , "Instagram" , "Linkedin" , "X"]
                                }, null, 8, ["modelValue", "onUpdate:modelValue"])
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_v_col, { cols: "3" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, {
                                  "hide-details": "",
                                  modelValue: reseau.user,
                                  "onUpdate:modelValue": ($event: any) => ((reseau.user) = $event),
                                  variant: "solo",
                                  label: "Pseudo"
                                }, null, 8, ["modelValue", "onUpdate:modelValue"])
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_btn, {
                        class: "mt-2",
                        onClick: _ctx.downloadVcf
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Télécharger")
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}