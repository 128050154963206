import { defineStore } from "pinia";
import httpService from "@/services/HttpService";
import config from "../config.json"
import keycloakService from "@/services/KeycloakService";

export interface Screen {
    id: string;
    url: string;
    user_id: string;
    img_url: string;
    thumbnail_url: string;
    created_at: Date;
}

export const useScreensStore = defineStore("Screens",{
    state : () => {
        return {
            data: [] as Screen[]
        }
    },
    actions : {
        fetchData() {
            httpService.getAxiosClient()
                .get(`${config.api_url}/screens`)
                .then(response=>{
                    this.data = response.data.screens
                    this.data.forEach(screen => {
                        screen.created_at = new Date(screen.created_at)
                    })
                    this.data.sort((a, b) => b.created_at.getTime() - a.created_at.getTime());
                })
        },
        async newScreen(url: string) {
            const response = await httpService.getAxiosClient()
                .post(`${config.api_url}/screens`, {
                    url: url,
                    user_id: keycloakService.GetUserId()
                });
            this.fetchData();
            // this.data.push(response.data.screen);
        },
        deleteScreen(id: string) {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const self = this
            httpService.getAxiosClient()
                .delete(`${config.api_url}/screens/${id}`)
                .then(response=> {
                    self.fetchData()
                })
        }
    },
    getters : {
        getScreensByUrl: (state)=>{
            return (url : string) => state.data.filter(usersUrls => usersUrls.url === url)
        },
        getScreensByHostname: (state) => {
            return (url : string) => state.data.filter(usersUrls => usersUrls.url.includes(url))
        },
    }
})