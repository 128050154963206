import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_expansion_panel_title = _resolveComponent("v-expansion-panel-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_sheet = _resolveComponent("v-sheet")!

  return (_openBlock(), _createBlock(_component_v_sheet, {
    rounded: "",
    "min-height": "80vh"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_container, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, {
            align: "center",
            "no-gutters": "",
            class: "mb-3"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { cols: "8" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    "hide-details": "",
                    modelValue: _ctx.url,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.url) = $event)),
                    variant: "underlined",
                    label: "Ajouter un url"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, { cols: "4" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (this.addUrl(_ctx.url)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Ajouter")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_row, {
            align: "center",
            "no-gutters": "",
            class: "mb-3"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { cols: "12" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    "hide-details": "",
                    modelValue: _ctx.filtre,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filtre) = $event)),
                    variant: "underlined",
                    label: "Filtrer"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_row, {
            "no-gutters": "",
            align: "center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { cols: "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_expansion_panels, { variant: "accordion" }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.hostnames, (hostname, index) => {
                        return (_openBlock(), _createBlock(_component_v_expansion_panel, { key: index }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_expansion_panel_title, {
                              onClick: ($event: any) => (_ctx.$emit('urlClicked',hostname))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(hostname) + " (" + _toDisplayString(_ctx.countUrlByHostname(hostname)) + ") ", 1)
                              ]),
                              _: 2
                            }, 1032, ["onClick"]),
                            _createVNode(_component_v_expansion_panel_text, null, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.urlByHostname(hostname), (url) => {
                                  return (_openBlock(), _createBlock(_component_v_list_item, {
                                    key: url.url,
                                    onClick: ($event: any) => (_ctx.$emit('urlClicked',url.url)),
                                    align: "start"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_row, { align: "center" }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_col, { style: {"text-wrap":"nowrap","text-overflow":"ellipsis","overflow":"hidden"} }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(_ctx.pathname(url.url)), 1)
                                            ]),
                                            _: 2
                                          }, 1024),
                                          _createVNode(_component_v_col, { cols: "auto" }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_btn, {
                                                icon: "mdi-check",
                                                size: "small",
                                                density: "compact",
                                                color: this.TasksStore.getTaskById(url.id) ? 'green' : 'grey',
                                                flat: true,
                                                onClick: ($event: any) => (this.TasksStore.getTaskById(url.id) ? this.TasksStore.deleteTask(this.TasksStore.getTaskById(url.id).id) : this.TasksStore.newTask(url.id))
                                              }, null, 8, ["color", "onClick"])
                                            ]),
                                            _: 2
                                          }, 1024),
                                          _createVNode(_component_v_col, { cols: "auto" }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_btn, {
                                                icon: "mdi-delete",
                                                size: "small",
                                                density: "compact",
                                                flat: true,
                                                onClick: ($event: any) => (this.removeUrl(url.id))
                                              }, null, 8, ["onClick"])
                                            ]),
                                            _: 2
                                          }, 1024)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick"]))
                                }), 128))
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}