import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia'
import router from './router'
import KeyCloakService from "@/services/KeycloakService";
import HttpService from "@/services/HttpService";
// import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@mdi/font/css/materialdesignicons.css'
// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'

const vuetify = createVuetify({
    components,
    directives,
})

const pinia = createPinia()
const renderApp = () => {
    createApp(App)
        .use(vuetify)
        .use(VueViewer,{debug:true})
        .use(pinia)
        .use(router)
        .mount('#app')
};

KeyCloakService.CallLogin(renderApp);
HttpService.configureAxiosKeycloak();