import { defineStore } from "pinia";
import httpService from "@/services/HttpService";
import config from "../config.json"
import keycloakService from "@/services/KeycloakService";
import {UsersUrl} from "@/stores/UsersUrlsStore";

export interface Task {
    id: string;
    task: string;
    user_id: string;
    users_urls: UsersUrl
    created_at: Date;
}

export const useTasksStore = defineStore("Tasks",{
    state : () => {
        return {
            data: [] as Task[]
        }
    },
    actions : {
        fetchData() {
            httpService.getAxiosClient()
                .get(`${config.api_url}/tasks`)
                .then(response=>{
                    this.data = response.data.tasks
                    this.data.forEach(task => {
                        task.created_at = new Date(task.created_at)
                    })
                    this.data.sort((a, b) => b.created_at.getTime() - a.created_at.getTime());
                })
        },
        async newTask(user_url_id: string) {
            const response = await httpService.getAxiosClient()
                .post(`${config.api_url}/tasks`, {
                    user_url_id: user_url_id,
//                    user_id: keycloakService.GetUserId()
                });
            this.fetchData();
            // this.data.push(response.data.screen);
        },
        deleteTask(id: string) {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const self = this
            httpService.getAxiosClient()
                .delete(`${config.api_url}/tasks/${id}`)
                .then(response=>{
                    self.fetchData()
                })
        }
    },
    getters : {
        getTaskByUrl: (state)=>{
            return (url : string) => state.data.find(task => task.users_urls.url === url)
        },
        getTaskById: (state)=>{
            console.log(state.data)
            return (id : string) => state.data.find(task => task.users_urls.id === id)
        }
    }
})