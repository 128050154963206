<script lang="ts">
import { defineComponent } from "vue";
import VCard from 'vcard-creator';

type Adresse = {
  nom: string ;
  rue: string;
  ville: string;
  cp: string;
  pays: string;
  region: string;
}
type Tel = {
  type: string;
  number: string;
  isPref: boolean;
}
type Url = {
  type: "WORK" | "HOME";
  url: string;
}
type Reseau = {
  url: string;
  type: "Facebook" | "Instagram" | "Linkedin" | "X" ;
  user: string | undefined;
}

export default defineComponent({
  data() {
    return {
      firstName: '',
      lastName: '',
      company: '',
      jobTitle: '',
      role: '',
      email: '',
      phone: '',
      // url : '',
      urls : [{
        url:'',
        type: 'WORK'
      }] as Url[],
      imageData: '' as string,
      adresses: [] as Adresse[],
      reseaux: [] as Reseau[],
      fileList: [],
      favTel: '',
      tels: [{
        type: 'WORK',
        number: '',
        isPref: true
      }] as Tel[]
    }
  },
  mounted() {
    this.addAddresse()
  },
  methods: {
    downloadVcf() {
      const vCard = new VCard()
      vCard
          .addName(this.lastName, this.firstName)
          .addCompany(this.company)
          .addJobtitle(this.jobTitle)
          .addRole(this.role)
          .addEmail(this.email,'PREF;WORK')
          // .addURL(this.url)

      //Réseaux
      this.reseaux.forEach(reseau=>{
        vCard.addSocial(reseau.url,reseau.type,reseau.user)
      })

      //urls
      this.urls.forEach(url=>{
        vCard.addURL(url.url,url.type)
      })

      //Numero de téléphones
      this.tels.forEach(tel => {
          vCard.addPhoneNumber(tel.number, tel.type + ';' + (tel.isPref ? 'PREF' : ''));
      })

      //Image
      if (this.imageData) {
        const mimeType = this.imageData.split(':')[1].split(';')[0];
        const mime = mimeType.split('/')[1];
        const base64img = this.imageData.split(',')[1];
        vCard.addPhoto(base64img, mime)
      }
      //Adresses
      this.adresses.forEach(adresse=>{
        vCard.addAddress(adresse.nom, '', adresse.rue, adresse.ville, adresse.region, adresse.cp, adresse.pays)
      })

      const anchor = document.createElement('a');
      anchor.href = 'data:application/octet-stream;base64,'+btoa(vCard.toString());
      anchor.target = '_blank';
      anchor.download = `${this.firstName.replaceAll(' ','-')}_${this.lastName.replaceAll(' ','-')}.vcf`;
      anchor.click();

      console.log(vCard.getProperties())
      console.log(vCard.toString())
    },
    handleFileUpload(event: Event) {
      const file = (event.target as HTMLInputElement).files?.[0];
      const reader = new FileReader();

      reader.onload = () => {
        console.log(reader.result)
        this.imageData = reader.result as string;
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    },
    addAddresse() {
      const adresse : Adresse = {
        nom: '',
        rue: '',
        ville: '',
        cp: '',
        pays: 'France',
        region :''
      }
      this.adresses.push(adresse)
    },
    removeAdresse(index:number) {
      this.adresses.splice(index,1)
    },
    addTel() {
      this.tels.push({
        type: '',
        number: '',
        isPref: false
      })
    },
    removeTel(index:number) {
      this.tels.splice(index,1)
    },
    setPrefTel(index:number) {
      this.tels.forEach(tel=>{
          tel.isPref = false
      })
      this.tels[index].isPref = true
    },
    addUrl() {
      this.urls.push({
        url: '',
        type: 'WORK'
      })
    },
    removeUrl(index:number) {
      this.urls.splice(index,1)
    },
    addReseau() {
      this.reseaux.push({
        url: "",
        type: "Facebook",
        user: undefined
      })
    },
    removeReseau(index:number) {
      this.reseaux.splice(index,1)
    },
  },
  computed: {

  }
})
</script>

<template>
  <v-sheet class="mx-auto" max-width="800">
    <v-form>
      <v-container>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field hide-details v-model="lastName" variant="solo" label="Nom"></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field hide-details v-model="firstName" variant="solo" label="Prénom"></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field hide-details v-model="company" variant="solo" label="Entreprise"></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field hide-details v-model="jobTitle" variant="solo" label="Métier"></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field hide-details v-model="email" variant="solo" label="Email"></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-file-input hide-details @change="handleFileUpload" accept="image/png, image/jpeg" label="File input" variant="solo"></v-file-input>
          </v-col>

          <v-divider class="huge_divider"></v-divider>

          <v-col cols="12" md="12">
            <v-row>
              <v-col cols="auto">
                <v-btn @click="addUrl" prepend-icon="mdi-plus">Ajouter un lien</v-btn>
              </v-col>
            </v-row>
            <v-row v-for="(url, index) in urls" :key="index" align="center">
              <v-col cols="auto">
                <v-btn icon="mdi-delete" color="red" density="comfortable" @click="removeUrl(index)"></v-btn>
              </v-col>
              <v-col>
                <v-text-field hide-details v-model="url.url" variant="solo" label="Lien"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-select
                    hide-details
                    label="Type"
                    v-model="url.type"
                    :items="['WORK', 'HOME']"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>

          <v-divider class="huge_divider"></v-divider>

          <v-col cols="12">
            <v-row>
              <v-col cols="auto">
                <v-btn @click="addTel" prepend-icon="mdi-plus">Ajouter un numéro de téléphone</v-btn>
              </v-col>
            </v-row>
            <v-row v-for="(tel,index) in tels" :key="index" align="center">
              <v-col cols="auto">
                <v-btn icon="mdi-delete" color="red" density="comfortable" @click="removeTel(index)"></v-btn>
              </v-col>
              <v-col cols="5">
                <v-text-field hide-details v-model="tel.number" variant="solo" label="Téléphone"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-select
                    hide-details
                    label="Type"
                    v-model="tel.type"
                    :items="['WORK', 'HOME']"
                ></v-select>
              </v-col>
              <v-col cols="2">
                <v-checkbox hide-details  v-model="tel.isPref" @click="setPrefTel(index)" label="Pref"></v-checkbox>
              </v-col>
            </v-row>
          </v-col>

          <v-divider class="huge_divider"></v-divider>

          <v-col cols="auto">
            <v-btn @click="addAddresse()" prepend-icon="mdi-plus">Ajouter une adresse</v-btn>
          </v-col>
          <v-col cols="12" v-for="(adresse, index) in this.adresses" :key="index">
            <v-row>
              <v-col cols="auto">
                <v-btn icon="mdi-delete" color="red" density="comfortable" @click="removeAdresse(index)"></v-btn>
              </v-col>
              <v-col>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field hide-details variant="solo" v-model="adresse.nom" label="Nom de l'adresse (domicile, travail, ...)"/>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field hide-details variant="solo" v-model="adresse.rue" label="Rue"/>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field hide-details variant="solo" v-model="adresse.ville" label="Ville"/>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field hide-details variant="solo" v-model="adresse.cp" label="Code postal"/>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field hide-details variant="solo" v-model="adresse.pays" label="Pays"/>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>

          <v-divider class="huge_divider"></v-divider>

          <v-col cols="12">
            <v-row>
              <v-col cols="auto">
                <v-btn @click="addReseau" prepend-icon="mdi-plus">Ajouter un réseau</v-btn>
              </v-col>
            </v-row>
            <v-row v-for="(reseau,index) in reseaux" :key="index" align="center">
              <v-col cols="auto">
                <v-btn icon="mdi-delete" color="red" density="comfortable" @click="removeReseau(index)"></v-btn>
              </v-col>
              <v-col cols="5">
                <v-text-field hide-details v-model="reseau.url" variant="solo" label="Lien"></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-select
                    hide-details
                    label="Type"
                    v-model="reseau.type"
                    :items='["Facebook" , "Instagram" , "Linkedin" , "X"]'
                ></v-select>
              </v-col>
              <v-col cols="3">
                <v-text-field hide-details v-model="reseau.user" variant="solo" label="Pseudo"></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-btn class="mt-2" @click="downloadVcf">Télécharger</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-sheet>
</template>

<style scoped lang="scss">
  .huge_divider {
    margin: 24px 0;
  }
</style>