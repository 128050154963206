import { defineStore } from "pinia";
import httpService from "@/services/HttpService";
import config from "../config.json"
import keycloakService from "@/services/KeycloakService";

export interface UsersUrl {
    id: string;
    url: string;
    user_id: string;
}

export const useUsersUrlsStore = defineStore("UsersUrls",{
    state : () => {
        return {
            data: [] as UsersUrl[]
        }
    },
    actions : {
        fetchData() {
            httpService.getAxiosClient()
                .get(`${config.api_url}/users_urls`)
                .then(response=>{
                    this.data = response.data.users_urls
                })
        },
        addUrl(url: string) {
            if (url.startsWith('http')) {
                httpService.getAxiosClient()
                    .post(`${config.api_url}/users_urls`,{
                        url:url
                    })
                    .then(response=>{
                        console.log(response.data.users_url)
                        this.data.push(response.data.users_url)
                    })
            } else {
                alert('Les urls doivent commencer par http ou https')
            }

        },
        removeUrl(id: string) {
            httpService.getAxiosClient()
                .delete(`${config.api_url}/users_urls/${id}`)
                .then(response=>{
                    this.fetchData()
                })
        }
    },
    getters : {
        getUsersUrlsByUrl: (state)=>{
            return (url : string) => state.data.filter(usersUrls=>usersUrls.url === url)
        }
    }
})