import { defineStore } from "pinia";
import httpService from "@/services/HttpService";
import config from "../config.json"
import keycloakService from "@/services/KeycloakService";
import {CheckyUrl} from "@/stores/CheckyUrlsStore";

interface CheckyUrlCheckLink {
    id: string;
    label: string;
    status: string;
    url_checked: string;
    created_at: Date;
    updated_at: Date;
}
export interface CheckyUrlCheck {
    id: string;
    url_id: string;
    status: string;
    links: CheckyUrlCheckLink[];
    created_at: Date;
    updated_at: Date;
}


export const useCheckyUrlsChecksStore = defineStore("CheckyUrlsChecksStore",{
    state : () => {
        return {
            data: [] as CheckyUrlCheck[]
        }
    },
    actions : {
        fetchData() {
            httpService.getAxiosClient()
                .get(`${config.checky_url}/url_checks`)
                .then(response=> {
                    this.data = response.data
                    this.data.forEach(checkyUrlCheck => {
                        checkyUrlCheck.created_at = new Date(checkyUrlCheck.created_at)
                        checkyUrlCheck.updated_at = new Date(checkyUrlCheck.updated_at)
                    })
                    this.data.sort((a, b) => b.created_at.getTime() - a.created_at.getTime());
                })
        },
        addCheck(url: CheckyUrl) {
            httpService.getAxiosClient()
                .post(`${config.checky_url}/url_checks`,{
                    ...url
                })
                .then(response=> {
                    this.fetchData()
                })
        },

    },
    getters : {
        getChecksByUrlId: (state) => {
            return (url_id : string) => state.data.filter(checkyUrlCheck => checkyUrlCheck.url_id === url_id)
        },
        getLastChecksByUrlId: (state) => {
            return (url_id : string) => state.data.filter(checkyUrlCheck => checkyUrlCheck.url_id === url_id)[0] as CheckyUrlCheck
        },
    }
})