<script lang="ts">
import {defineComponent} from "vue";
import {mapActions, mapState, mapStores} from "pinia";
import {CheckyUrl, useCheckyUrlsStore} from "@/stores/CheckyUrlsStore";
import {useCheckyUrlsChecksStore} from "@/stores/CheckyUrlsChecksStore";
import {useCheckyUrlsCronsStore} from "@/stores/CheckyUrlsCronStore";

export default defineComponent({
  name: "CheckyView",
  data () {
    return {
      selectedUrls: [] as string[],
      newUrl: ''
    }
  },
  beforeMount() {
    useCheckyUrlsStore().fetchData()
    useCheckyUrlsChecksStore().fetchData()
    useCheckyUrlsCronsStore().fetchData()
  },
  methods: {
    addCheck() {
      this.selectedUrls.forEach(url_id => {
        useCheckyUrlsChecksStore().addCheck(this.getUrlById(url_id))
      })
      this.selectedUrls = []
    },
    addCron() {
      this.selectedUrls.forEach(url => {
        useCheckyUrlsCronsStore().addCron(this.getUrlById(url))
      })
      this.selectedUrls = []
    },
    removeCron() {
      this.selectedUrls.forEach(url => {
        const urlId = this.getUrlById(url).id
        useCheckyUrlsCronsStore().deleteCron(this.getCheckCronByUrlId(urlId).id)
      })
      this.selectedUrls = []
    },
    removeUrl() {
      this.selectedUrls.forEach(url => {
        const urlId = this.getUrlById(url).id
        useCheckyUrlsStore().deleteUrl(urlId)
      })
      this.selectedUrls = []
    },
    formatDate(date: Date) {
      if (date) {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Les mois commencent à 0
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
      }

    },
    ...mapActions(useCheckyUrlsStore,['addUrl'])
  },
  computed: {
    ...mapState(useCheckyUrlsStore,['getUrls','getUrlById']),
    ...mapState(useCheckyUrlsChecksStore,['getChecksByUrlId','getLastChecksByUrlId']),
    ...mapState(useCheckyUrlsCronsStore,['getCheckCronByUrlId']),
  }
})
</script>
<template>
  <v-container>
    <v-row>
      <v-col align-self="center">
        <v-text-field hide-details="auto" label="Ajouter un nouvel url" v-model="newUrl"></v-text-field>
      </v-col>
      <v-col cols="auto" align-self="center">
        <v-btn @click="this.addUrl(newUrl)">Ajouter l'url</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="auto">
        <v-btn @click="addCheck" :disabled="selectedUrls.length === 0">
          Verifier
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn @click="addCron" :disabled="selectedUrls.length === 0">
          Ajouter verification régulière
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn @click="removeCron" :disabled="selectedUrls.length === 0">
          Supprimer verification régulière
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn @click="removeUrl" :disabled="selectedUrls.length === 0">
          Supprimer urls
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-table>
          <thead>
          <tr>
            <th></th>
            <th class="text-center">
              Url
            </th>
            <th class="text-center">
              Last status
            </th>
            <th class="text-center">
              Nb liens morts
            </th>
            <th class="text-center">
              Last check
            </th>
            <th class="text-center">Est verifié</th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="checkyUrl in this.getUrls"
              :key="checkyUrl.id"
          >
            <td>
              <v-checkbox
                  v-model="selectedUrls"
                  :value="checkyUrl.id"
                  hide-details="true"
              ></v-checkbox>
            </td>
            <td>{{ checkyUrl.url }}</td>
            <td>{{ this.getLastChecksByUrlId(checkyUrl.id)?.status }}</td>
            <td>
              <v-tooltip location="top center" origin="auto">
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props" class="my-12" :text="this.getLastChecksByUrlId(checkyUrl.id)?.links?.filter(check=> !(check.status < 400 && check.status >= 200) ).length"></v-btn>
                </template>

                <ul>
                  <li v-for="urlChecked in this.getLastChecksByUrlId(checkyUrl.id)?.links?.filter(check=> !(check.status < 400 && check.status >= 200))" :key="urlChecked.id">
                    {{ urlChecked.label }} => {{ urlChecked.url_checked }} : {{ urlChecked.status }}
                  </li>
                </ul>
              </v-tooltip>

            </td>
            <td>{{ formatDate(this.getLastChecksByUrlId(checkyUrl.id)?.created_at) }}</td>
            <td>
              <v-icon v-if="!!this.getCheckCronByUrlId(checkyUrl.id)"
                      icon="mdi-check"
                      color="green"
              ></v-icon>
              <v-icon v-else
                      icon="mdi-close"
                      color="red">

              </v-icon>
            </td>
          </tr>
          </tbody>
        </v-table>
      </v-col>
    </v-row>

  </v-container>

</template>
