import { defineStore } from "pinia";
import httpService from "@/services/HttpService";
import config from "../config.json"
import keycloakService from "@/services/KeycloakService";

export interface CheckyUrl {
    id: string;
    url: string;
    user_id: string;
    created_at: Date;
    updated_at: Date;
}

export const useCheckyUrlsStore = defineStore("CheckyUrls",{
    state : () => {
        return {
            data: [] as CheckyUrl[]
        }
    },
    actions : {
        fetchData() {
            httpService.getAxiosClient()
                .get(`${config.checky_url}/urls`)
                .then(response=> {
                    this.data = response.data
                    this.data.forEach(checkyUrl => {
                        checkyUrl.created_at = new Date(checkyUrl.created_at)
                        checkyUrl.updated_at = new Date(checkyUrl.updated_at)
                    })
                    this.data.sort((a, b) => b.created_at.getTime() - a.created_at.getTime());
                })
        },
        addUrl(url: string) {
            httpService.getAxiosClient()
                .post(`${config.checky_url}/urls`,{
                    url
                })
                .then(response=> {
                    this.fetchData()
                })
        },
        deleteUrl(id: string) {
            httpService.getAxiosClient()
                .delete(`${config.checky_url}/urls/${id}`)
                .then(response=> {
                    this.fetchData()
                })
        }
    },
    getters : {
        getUrlById: (state) => {
            return (id : string) : CheckyUrl => state.data.find(checkyUrl => checkyUrl.id === id) as CheckyUrl
        },
        getUrls: (state) => {
            return state.data
        },
    }
})