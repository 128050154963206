<script lang="ts">
import { defineComponent } from "vue";
import UsersUrlsView from "@/components/UsersUrlsView.vue";
import UsersUrlView from "@/components/UsersUrlView.vue";

export default defineComponent({
  components: {UsersUrlView, UsersUrlsView},
  data() {
    return {
      url : ''
    }
  },

  methods: {
    onUrlClicked(url : string) {
      this.url = url
    }
  },
  computed: {

  }
})
</script>

<template>

  <v-container fill-height fluid>
    <v-row>
      <v-col cols="4" lg="3" xl="2">
        <UsersUrlsView @urlClicked="onUrlClicked"/>
      </v-col>
      <v-col cols="8" lg="9" xl="10">
        <UsersUrlView v-if="url" :url="url"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped lang="scss">
  .container {
    display: flex;
    width: 100%;
    gap: 16px;
  }
</style>